<template>
  <v-card
    v-if="poData.project"
    class="app-po-editable"
  >
  <v-form
        ref="refPurchaseOrderForm"
        @submit.prevent="handleFormSubmit"
      >
    <!-- Header -->
    <v-card-text class="pa-4">
      <div class="po-header d-flex flex-wrap justify-space-between">
        <!-- Left Content -->
        <div class="mb-0">
          <div class="mb-4" v-if="poData.project.billed_by">
            <div class="d-flex align-center mb-6">
              <v-img
                :src="prsinvivo"
                max-height="50px"
                max-width="50px"
                alt="logo"
                contain
                class="me-3"
              ></v-img>
            </div>
              <span class="text--primary font-weight-bold text-uppercase">
                {{ poData.project.billed_by.code }}
              </span>
            <span class="d-block">{{ poData.project.billed_by.address1 }}</span>
            <span class="d-block">{{ poData.project.billed_by.address2 }}</span>
            <span class="d-block">{{ poData.project.billed_by.zip_code }} {{ poData.project.billed_by.city }}</span>
            <span class="d-block">{{ poData.project.billed_by.country }}</span>
            <span class="d-block">{{ poData.project.billed_by.phone_number }}</span>
            <span class="d-block">Tax # {{ poData.project.billed_by.tax_id }}</span>
            <span class="d-block" v-if="poData.project.billed_by.org_number">Org # {{ poData.project.billed_by.org_number }}</span>
          </div>
          <div class="d-flex align-center justify-end" v-if="isProject">
              <span class="me-2">Project: </span>
              <v-select
                v-model="poData.project"
                outlined
                dense
                :items="projectOptions"
                :loading="projectLoading"
                item-text="job_number"
                item-value="job_number"
                placeholder="Select Project"
                label="Select Project"
                hide-details="auto"
                class="header-inputs flex-grow-0"
                return-object
                :rules="[validators.required]"
              ></v-select>
            </div>
        </div>
        
        <!-- Right Content -->
        <div :class="$vuetify.rtl ? 'text-left' : 'text-right'">
          <div class="mb-2 d-flex align-center justify-end" v-if="poData.id">
            <span class="me-2 font-weight-medium text-xl text--primary">PO:</span>
            <v-text-field
              v-model.number="poData.id"
              outlined
              disabled
              dense
              class="header-inputs flex-grow-0"
              hide-details="auto"
            ></v-text-field>
          </div>
          
          
          <div class="mb-2 d-flex align-center justify-end">
            <span class="me-2">Date Created: </span>
            
            <v-text-field
              v-model="poData.created_at"
              class="header-inputs flex-grow-0"
              readonly
              outlined
              dense
              hide-details="auto"
              disabled
            ></v-text-field>
          </div>
          <!-- <div class="mb-2 d-flex align-center justify-end">
            <span class="me-2">Date Valid. & Sent Final: </span>
            <v-text-field
              v-model="poData.issue_date"
              class="header-inputs flex-grow-0"
              readonly
              outlined
              dense
              hide-details="auto"
              disabled
            ></v-text-field>
          </div> -->
          <div class="mb-2 d-flex align-center justify-end">
            <span class="me-2">Date of Serv. Rendered: </span>
            <v-menu
              v-model="isServiceDateMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              eager
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="poData.service_date"
                  class="header-inputs flex-grow-0"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[validators.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="poData.service_date"
                @input="isServiceDateMenuOpen = false"
              ></v-date-picker>
            </v-menu>
          </div>
          
        </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <!-- Payment Details -->
    <v-card-text class="py-5 px-8">
      <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row">
        <div class="mb-6 mb-sm-0">
          <p class="font-weight-semibold payment-details-header">
            PO created for:
          </p>
          <v-autocomplete
            v-model="poData.supplier"
            outlined
            :menu-props="{ offsetY: true }"
            :items="supplierOptions"
            :loading="supplierLoading"
            dense
            item-text="name"
            item-value="id"
            return-object
            placeholder="Select Supplier"
            class="select-po-to"
            @change="updateSupplierContact"
            :rules="[validators.required]"
          ></v-autocomplete>
          <v-row align="center" class="mb-1" >
            <p class="mb-0 mr-1">
              To the attention of 
            </p>
            <v-autocomplete
              v-model="poData.supplier_contact"
              outlined
              :menu-props="{ offsetY: true }"
              :items="contactsBySupplier"
              :loading="supplierContactLoading"
              dense
              item-text="name"
              item-value="id"
              hide-details="auto"
              placeholder="Select Supplier Contact"
              class="select-po-to"
              :rules="[validators.required]"
            ></v-autocomplete>
          </v-row>
          <p class="mb-1">
            {{ poData.supplier.address1 }}
          </p>
          <p
            v-if="poData.supplier.address2"
            class="mb-1"
          >
            {{ poData.supplier.address2 }}
          </p>
          <p
            v-if="poData.supplier.zip_code"
            class="mb-1"
          >
            {{ poData.supplier.zip_code }}, {{ poData.supplier.city }}
          </p>
          <p class="mb-1" v-if="poData.supplier.country">
            {{ poData.supplier.country.name }}
          </p>
          <p class="mb-0">
            {{displaySupplierContactEmail()}}
          </p>
        </div>
        <div>
              <p class="mb-0 font-weight-semibold payment-details-header">
                The following reference number must appear on any
              </p>
              <p class="font-weight-semibold payment-details-header">
correspondence, delivery document or po :
              </p>
              <table>
                <tr v-if="poData.id">
                  <td class="pe-6">
                    PO NUMBER:
                  </td>
                  <td>
                    {{ poData.id }}
                  </td>
                </tr>
                <tr v-if="poData.project">
                  <td class="pe-6">
                    JOB NUMBER:
                  </td>
                  <td>
                    {{ poData.project.ges_bva ? `${poData.project.job_number} / ${poData.project.ges_bva}` : poData.project.job_number}}
                  </td>
                </tr>
                <tr v-if="poData.project">
                  <td class="pe-6">
                    JOB NAME:
                  </td>
                  <td>
                    {{ poData.project.job_name }}
                  </td>
                </tr>
              </table>
            </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <!-- Add purchased products -->
    <div class="add-products-form pt-9 pb-10 px-8">
      <div
        v-for="(purchasedProduct, purchasedProductIndex) in poData.po_items"
        :key="purchasedProductIndex"
        class="single-product-form"
      >
        <div class="add-products-header mb-2 d-none d-md-flex">
          <div class="px-5 flex-grow-1 font-weight-semibold">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <span>Item</span>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <span>Quantity</span>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <span>Unit Price</span>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <span>Amount</span>
              </v-col>
            </v-row>
          </div>
          <div class="header-spacer"></div>
        </div>
        <v-card
          outlined
          class="d-flex"
        >
          <!-- Left Form -->
          <div class="pa-5 flex-grow-1">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="purchasedProduct.order_item_label"
                  readonly
                  outlined
                  dense
                  disabled
                  hide-details="auto"
                  class="mb-3"
                  v-if="purchasedProduct.order_item_label"
                ></v-text-field>
                <v-select
                  v-else
                  v-model="purchasedProduct.order_item"
                  outlined
                  dense
                  :items="availableOrderItem"
                  :loading="orderItemLoading"
                  item-text="label"
                  item-value="value"
                  :menu-props="{ offsetY : true }"
                  placeholder="Select Item"
                  label="Select Item"
                  hide-details="auto"
                  class="mb-3"
                  :rules="[validators.required]"
                  @input="obj => updateItemForm(purchasedProductIndex, obj)"
                ></v-select>
                <v-textarea
                  v-model="purchasedProduct.description"
                  outlined
                  label="Description"
                  rows="3"
                  hide-details="auto"
                  placeholder="Description"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="4"
              >
                <v-text-field
                  v-model.number="purchasedProduct.qty"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  label="Quantity"
                  placeholder="$"
                  :rules="[validators.required]"
                  @input="val => { purchasedProduct.qty = Math.abs(val) }"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="4"
              >
                <v-text-field
                  v-model.number="purchasedProduct.unit_price"
                  outlined
                  dense
                  type="number"
                  hide-details="auto"
                  label="Unit Price"
                  placeholder="Unit Price"
                  :rules="[validators.required, validators.decimalValidator]"
                  @input="val => { purchasedProduct.unit_price = val }"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="4"
              >
                <p class="my-2">
                  <span class="d-inline d-md-none">Amount: </span>
                  <span>{{(purchasedProduct.qty * purchasedProduct.unit_price).toLocaleString('fr-FR', { style: 'currency', currency: currency})}}</span>
                </p>
              </v-col>
            </v-row>
          </div>
          <!-- Item Actions -->
          <div class="d-flex flex-column item-actions rounded-0 pa-1">
            <v-btn
              icon
              small
              @click="poData.po_items.splice(purchasedProductIndex, 1)"
            >
              <v-icon size="20">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
            
          </div>
        </v-card>
      </div>
      <v-btn
        color="primary"
        class="mt-4"
        outlined
        @click="addNewItem"
        v-if="availableOrderItem.length > 0"
      >
        Add Item
      </v-btn>
    </div>

    <v-divider></v-divider>

    <!-- Total -->
    <v-card-text class="py-9 px-8">
      <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row">
        <div class="mb-6 mb-sm-0">
          <div class="mb-4 d-flex align-center">
            <span class="font-weight-semibold me-2">PRS IN VIVO Contact:</span>
            <v-select
              v-model="poData.contact"
              outlined
              :items="userOptions"
              :loading="userLoading"
              dense
              item-text="name"
              item-value="azure_ad_id"
              hide-details="auto"
              placeholder="Select Contact"
              :rules="[validators.requiredArray]"
            ></v-select>
          </div>
          <p class="mb-0">
            <span class="font-weight-semibold">1: </span>
            <span>This order must be honored in accordance with the prices,</span>
          </p>
          <p class="mb-0"><span>conditions and delivery types above.</span></p>
          <p class="mb-0">
            <span class="font-weight-semibold">2: </span>
            <span>If unable to honor this order, please notify us immediately.</span>
          </p>
          <p class="mb-0">
            <span class="font-weight-semibold">3: </span>
            <span>Please send your invoices by mail in two copies to :</span>
          </p>
          <div v-if="poData.project.billed_by">
            <p class="mb-0"><span class="font-weight-semibold">{{ poData.project.billed_by.address1 }}</span></p>
            <p class="mb-0"><span class="font-weight-semibold">{{ poData.project.billed_by.address2 }}</span></p>
            <p class="mb-0"><span class="font-weight-semibold">{{ poData.project.billed_by.zip_code }} {{ poData.project.billed_by.city }}</span></p>
            <p class="mb-0"><span class="font-weight-semibold">{{ poData.project.billed_by.country }}</span></p>
            <p class="mb-0"><span class="font-weight-semibold">Phone: {{ poData.project.billed_by.phone_number }}</span></p>
            <p class="mb-0" v-if="poData.contact">
              <span class="font-weight-semibold" v-if="typeof(poData.contact) == 'object'">or by email at {{poData.contact.email }}</span>
              <span class="font-weight-semibold" v-if="userOptions.filter(user => user.azure_ad_id == poData.contact).length > 0">or by email at {{userOptions.filter(user => user.azure_ad_id == poData.contact)[0].email }}</span>
            </p>
          </div>
        </div>
        <div v-if="poData.po_items">
          <table class="w-full">
            <tr>
              <td class="pe-16">
                Subtotal:
              </td>
              <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                {{totalPreTax.toLocaleString('fr-FR', { style: 'currency', currency: currency})}} 
              </th>
            </tr>
            <tr>
              <td class="pe-16">
                Tax:
              </td>
              <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                {{tax.toLocaleString('fr-FR', { style: 'percent', maximumFractionDigits: 2})}}
              </th>
            </tr>
          </table>
          <v-divider class="mt-4 mb-3"></v-divider>
          <table class="w-full mb-4">
            <tr>
              <td class="pe-16">
                Total:
              </td>
              <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                {{totalPostTax.toLocaleString('fr-FR', { style: 'currency', currency: currency})}}
              </th>
            </tr>
          </table>
          <v-row align="center" class="mb-1" >
            <p class="mb-0 mr-1">
              Currency 
            </p>
            <v-select
              v-model="poData.currency"
              outlined
              :items="currencyOptions"
              :loading="currencyLoading"
              dense
              item-text="name"
              item-value="id"
              hide-details="auto"
              placeholder="Select Currency"
              class="select-po-to"
              return-object
              :rules="[validators.required]"
            ></v-select>
          </v-row>
        </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-text class="px-8 py-6">
      <div class="mb-0">
        <p class="font-weight-semibold mb-2">
          Note:
        </p>
        <v-textarea
          v-model="poData.note"
          outlined
          rows="3"
          hide-details="auto"
          placeholder="It was a pleasure working with you and your team. Thank You!"
        ></v-textarea>
      </div>
    </v-card-text>
    </v-form>
  </v-card>
</template>

<script>

import router from '@/router'
import themeConfig from '@themeConfig'
import prsinvivo from '@images/logos/prsinvivo.png'
import { ref, onMounted, computed, watch } from '@vue/composition-api'
import { required, requiredArray, decimalValidator } from '@core/utils/validation'
import { mdiClose, mdiCogOutline } from '@mdi/js'
import usePO from '../usePO'

export default {
  props: {
    poData: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: false,
      default: () => {}
    },
    originalPOAmount: {
      type: Number,
      required: false,
      default: () => 0
    },
  },
  setup(props, {emit}) {
    
    const isServiceDateMenuOpen = ref(false)
    const project = ref({})
    project.value = JSON.parse(JSON.stringify(router.currentRoute.params || {}))
    
    const isProject = ref(false)
    isProject.value = project.value.projectData  && Object.keys(project.value.projectData).length === 0 && Object.getPrototypeOf(project.value.projectData) === Object.prototype

    const refPurchaseOrderForm = ref(null)
    const {
        projectOptions,
        projectLoading,
        projectSearch,
        supplierOptions,
        supplierLoading,
        supplierSearch,
        supplierContactOptions,
        supplierContactLoading,
        supplierContactSearch,
        orderItemLoading,
        orderItemOptions,
        orderItemSearch,
        currencyOptions,
        currencyLoading,
        currencySearch,
        
        userLoading,
        userOptions,
        userSearch,

        fetchSuppliers,
        fetchSupplierContacts,
        fetchOrderItems,
        fetchUsers,
        fetchCurrencies,
        fetchProjects,
    } = usePO()

    

    const updateItemForm = (index, val) => {
      /* eslint-disable no-param-reassign */
      props.poData.po_items[index].order_item_label = orderItemOptions.value.filter(item => item.value == val)[0].label
      props.poData.po_items[index].qty = 0
      props.poData.po_items[index].unit_price = 0
      props.poData.po_items[index].description = ''
      /* eslint-enable */
    }

    const addNewItem = () => {
      props.poData.po_items.push({
        id: '',
        qty: 0,
        unit_price: 0,
        order_item: '',
      })
    }

    const totalPreTax = computed(() => {
      var total = 0
      for(const item of props.poData.po_items){
        total += item.unit_price * item.qty
      }
      return total
    })

    const tax = 0.0

    const totalPostTax = computed(() => {
      const total = totalPreTax.value * (1+tax)
      if (total > (1+0.15)*props.originalPOAmount){
        emit('warning', true)
      } else {
        emit('warning', false)
      }
      return total
    })

    const currency = computed(() => {
      const option =  currencyOptions.value.filter(item => item.id == props.poData.currency.id)
      if (option.length) return option[0].name
      else return 'EUR'
    })

    const availableOrderItem = computed(() => {
        let used = props.poData.po_items ? props.poData.po_items.map(item => item.order_item) : []
        return orderItemOptions.value.filter(item => {
            return !used.includes(item.value)
        })
    })

    const contactsBySupplier = computed(() => {
      return supplierContactOptions.value.filter(item => item.suppliers.includes(props.poData.supplier.id))
    })

    const updateSupplierContact = () => {
      props.poData.supplier_contact = null
    }

    const displaySupplierContactEmail = () => {
      const contact =  supplierContactOptions.value.filter(item => item.id == props.poData.supplier_contact)
      if (contact.length) return contact[0].email
      else return ''
    }



    onMounted( () => {
      fetchProjects()
      fetchSuppliers()
      fetchUsers()
      fetchSupplierContacts()
      fetchOrderItems()
      fetchCurrencies()
      if (props.poData.contact) props.poData.contact = props.poData.contact.azure_ad_id
      console.log(props.poData.contact)
    })

    watch(
      () => props.projectData,
      () => {
        props.poData.project = props.projectData
      }
    )

    

    return {
      isProject,
      isServiceDateMenuOpen,
      refPurchaseOrderForm,
      
      projectOptions,
      projectLoading,
      projectSearch,
      userLoading,
      userOptions,
      userSearch,
      supplierOptions,
      supplierLoading,
      supplierSearch,
      contactsBySupplier,
      supplierContactLoading,
      supplierContactSearch,
      displaySupplierContactEmail,
      orderItemLoading,
      orderItemOptions,
      orderItemSearch,
      availableOrderItem,
      updateSupplierContact,
      currencyOptions,
      currencyLoading,
      currencySearch,
      currency,
      
      updateItemForm,
      addNewItem,
      totalPreTax,
      tax,
      totalPostTax,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      prsinvivo,

      // Icons
      icons: {
        mdiClose,
        mdiCogOutline,
      },

      // Field Validators
      validators: {
        required,
        requiredArray,
        decimalValidator
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/po.scss';

.app-po-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-po-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
